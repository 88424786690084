function generateLinkItem(title, href) {
	let anchor = document.createElement("a")
	anchor.classList.add("nav-link")
	anchor.innerHTML = title
	anchor.setAttribute("href", href)

	let navItem = document.createElement("li")
	navItem.classList.add("nav-item")
	navItem.appendChild(anchor)

	return navItem;
}

// Page title and reference
const pages = [
	["CHI SIAMO", "/#inostriservizi"],
	["DOVE SIAMO", "/#contattaci"],
	["CONTATTI", "/#contattaci"]
];
let navbar = document.getElementById("#NavbarLinks")

pages.forEach(page => {
	let link = generateLinkItem(page[0], page[1]);
	navbar.appendChild(link);
});
